@import 'variables';

.contact {
  padding: 60px 0;

  .contact-form {
    background-color: #fff;
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
  }
}
