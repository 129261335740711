@import 'variables';

.latest-articles {
  background-color: $light-background;
  border: 1px solid color-mix(in srgb, $light-background, transparent 90%);
  padding: 30px;
  border-radius: $border-radius;

  .title {
    color: $primary;
    font-size: 20px;
    font-weight: 600;
    padding-inline-start: 20px;
    margin: 0 0 20px 0;
    border-inline-start: 4px solid $secondary;
  }
}
