@import 'variables';

.latest-post {
  display: flex;
  margin-bottom: 15px;

  .image {
    width: 80px;
    margin-inline-end: 15px;
    border-radius: $border-radius;
  }

  .title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;

    .link {
      color: $primary;
      transition: 0.3s;
      text-decoration: none;
    }
  }

  .date {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: color-mix(in srgb, $tertiary, transparent 50%);
  }
}
