@import 'variables';

.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: $border-radius;
  padding: 20px 0 30px 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  .icon {
    font-size: 20px;
    color: $secondary;
    width: 56px;
    height: 56px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    border: 2px dotted color-mix(in srgb, $secondary, transparent 40%);
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
    color: $primary !important;
  }

  .description {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;

    .link {
      text-decoration: none;
      color: $primary;
    }
  }
}
