@import 'variables';

.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;

  .title {
    color: $tertiary;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 50px;
      height: 3px;
      background: $secondary;
      inset-inline: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
