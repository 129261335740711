@import 'variables';

.hero {
  height: calc(100vh - 82px);
  margin-top: 82px;

  .swiper {
    width: 100%;
    height: 100%;

    .slide {
      position: relative;
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .background {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .content {
        position: absolute;
        top: 40%;
        width: 50%;
        padding-inline: 80px;
        color: #fff;
        z-index: 2;

        h1 {
          color: $secondary;
        }

        .description {
          font-size: 24px;
        }

        @media only screen and (max-width: 1440px) {
          & {
            width: 70%;
            padding-inline: 40px;
          }
        }

        @media only screen and (max-width: 1200px) {
          & {
            width: 85%;
          }
        }

        @media only screen and (max-width: 992px) {
          & {
            top: 35%;
            width: 100%;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        z-index: 1;
      }
    }
  }
}
