@import 'variables';

.tag {
  border-radius: $border-radius;
  padding-block: 6px;
  padding-inline: 16px;
  margin-inline-end: 8px;
  margin-bottom: 4px;
  background-color: $secondary;
  color: #fff;

  &.sm {
    font-size: 12px !important;
  }
}
