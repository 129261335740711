@import 'variables';

.page-title {
  margin-top: 90px;
  background-color: $light-background;
  padding-block: 24px;

  .title {
    color: $primary;
  }

  .breadcrumbs {
    .list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 14px;

      .item {
        .link {
          text-decoration: none;
          color: $primary;
          transition: 0.4s;

          &:hover {
            color: $secondary;
          }
        }

        .text {
          color: $tertiary;
        }
      }

      .item + .item {
        padding-inline-start: 10px;

        &::before {
          content: '/';
          display: inline-block;
          padding-inline-end: 10px;
          color: color-mix(in srgb, $primary, transparent 70%);
        }
      }
    }
  }
}
