@import 'variables';

.navbar {
  background-color: $primary;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  .link {
    color: #fff !important;
    font-weight: bold;
    transition: 0.4s;

    &.active {
      color: $secondary !important;
    }

    &:hover {
      color: $secondary !important;
    }
  }
}
