.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 20%);

  .grid-item {
    padding: 16px;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 25%);
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 33.33%);
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media only screen and (max-width: 575px) {
    grid-template-columns: 100%;
  }
}
