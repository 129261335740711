@import 'variables';

.team-card {
  background-color: #fff;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  border-radius: $border-radius;
  transition: 0.4s;

  .member-img-container {
    position: relative;
    overflow: hidden;

    .member-img {
      max-width: 100%;
      height: auto;
    }

    .social-media-links {
      position: absolute;
      inset-inline-end: -100%;
      top: 30px;
      opacity: 0;
      border-radius: 4px;
      transition: 0.5s;
      background: color-mix(in srgb, #fff, transparent 60%);
      z-index: 2;

      .social-media-link {
        transition: color 0.3s;
        color: color-mix(in srgb, #3d4348, transparent 50%);
        margin: 15px 12px;
        display: block;
        line-height: 0;
        text-align: center;

        .icon {
          font-size: 18px;
          transition: 0.4s;

          &.x:hover {
            color: #000;
          }

          &.facebook:hover {
            color: #1877f2;
          }

          &.instagram:hover {
            color: #cd486b;
          }

          &.linkedin:hover {
            color: #0077b5;
          }
        }
      }
    }
  }

  .member-info {
    padding: 10px 15px 20px 15px;

    .name {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 20px;
    }

    .position {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: color-mix(in srgb, #3d4348, transparent 50%);
    }

    .description {
      font-style: italic;
      font-size: 14px;
      padding-top: 15px;
      line-height: 26px;
      color: color-mix(in srgb, #3d4348, transparent 30%);
    }
  }

  &:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);

    .social-media-links {
      inset-inline-end: 8px;
      opacity: 1;
    }
  }
}
