@import 'variables';

.about {
  padding: 60px 0;

  p {
    text-align: justify;
  }

  .img-container {
    position: relative;
    min-height: 400px;

    @media only screen and (min-width: 992px) {
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      min-height: 600px;
    }

    .img {
      position: absolute;
      inset: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
      border-start-end-radius: 50px;
      border-end-start-radius: 50px;

      @media only screen and (max-width: 992px) {
        position: relative;
      }
    }
  }

  .title {
    margin: 30px 0;
    color: $tertiary;
    font-size: 2.75rem;
    font-weight: 700;

    @media only screen and (min-width: 992px) {
      max-width: 65%;
      margin: 0 0 80px 0;
    }
  }

  .story {
    padding: 40px;
    background-color: color-mix(in srgb, $primary, transparent 96%);
    border-start-end-radius: 50px;
    border-end-start-radius: 50px;

    .story-title {
      color: $tertiary;
      text-transform: uppercase;
      font-size: 1.1rem;
      color: color-mix(in srgb, $primary, transparent 50%);
    }

    @media only screen and (min-width: 992px) {
      padding-inline-end: 35%;
    }
  }
}
