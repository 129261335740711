@import 'variables';

.navbar {
  background: #fff;
  transition: 0.4s;

  .link {
    transition: 0.4s;
    color: $secondary !important;
    font-weight: bold;

    &:hover {
      color: $primary !important;
    }
  }

  &.scrolled {
    background-color: $primary;
    box-shadow:
      rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    .link {
      color: #fff !important;

      &.active {
        color: $secondary !important;
      }

      &:hover {
        color: $secondary !important;
      }
    }
  }
}
