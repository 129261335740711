@import 'variables';

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 4px;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

body {
  font-family: '29LTBukra' !important;
}

section {
  scroll-margin-top: 3rem;
}

.navbar-toggler {
  border-color: $primary !important;

  .navbar-toggler-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%283, 70, 83, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e') !important;
  }

  &.scrolled {
    border-color: #fff !important;

    .navbar-toggler-icon {
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e') !important;
    }
  }
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: $primary-hover !important;
    border-color: $primary-hover !important;
  }
}

.btn-secondary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.btn-outline-primary {
  border-color: $primary !important;
  color: $primary !important;

  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
    color: #fff !important;
  }
}

.btn-outline-secondary {
  border-color: $secondary !important;
  color: $secondary !important;

  &:hover {
    background-color: $secondary !important;
    border-color: $secondary !important;
    color: #fff !important;
  }
}

.form-control {
  direction: rtl !important;
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none !important;
  color: $primary;
  border-color: color-mix(in srgb, $primary, transparent 80%) !important;
  font-weight: 400;
  line-height: 1.5;
  color: $tertiary !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: $border-radius;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: $secondary !important;
  }
}

.form-select {
  box-shadow: none !important;
  color: $tertiary !important;
  --bs-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2366cc99%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;

  &:focus {
    border-color: $secondary !important;
  }
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(1 * 2));
}

::placeholder {
  color: #999 !important;
  opacity: 1;
}

.dropdown-item:active {
  background-color: $secondary !important;
}

.text-hover-secondary:hover {
  color: $secondary !important;
}

.transition-0-4 {
  transition: 0.4s;
}
