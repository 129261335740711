@import 'variables';

.report-card {
  position: relative;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: $border-radius;

  .report-card-background-icon {
    opacity: 0.1;
    color: $primary;
    position: absolute;
    top: 40%;
    inset-inline-end: 20%;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    font-size: 120px;
    z-index: 1;
  }

  &:hover {
    .report-card-background-icon {
      opacity: 0.2;
    }
  }

  .report-card-content {
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    z-index: 2;

    .report-card-title {
      font-size: 20px;
      color: $primary;
      font-weight: 700;
      transition: 0.3s;
      margin-bottom: 15px;
    }

    .report-card-author {
      color: #aaa;

      img {
        border-radius: 50%;
        margin-inline-end: 4px;
      }
    }

    .description {
      color: #111;
    }

    .report-card-splitter {
      color: color-mix(in srgb, $primary, transparent 60%);
      margin-block: 8px;
    }

    .report-card-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      line-height: 1;
      transition: 0.3s;
      color: $secondary !important;
      text-decoration: none;
      width: 100%;
      text-align: center;
      border: 1px solid $secondary;
      padding-block: 12px;
      border-radius: $border-radius;

      &:hover {
        color: $secondary-hover !important;
        border-color: $secondary-hover;
      }

      .report-card-link-text {
        margin-inline-end: 8px;
      }
    }
  }
}
