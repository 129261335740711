@import 'variables';

.partners {
  padding: 60px 0;
  background-color: $light-background;

  .swiper {
    width: 100%;
    height: 100%;

    .slide {
      text-align: center;
      font-size: 18px;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 100%;
        height: 40px;
        object-fit: contain;
        transition: 0.4s;
        filter: grayscale(1);

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
}
