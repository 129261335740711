@import 'variables';

.news-details {
  padding-block: 50px;

  .image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: $border-radius;
  }

  .content {
    padding: 24px;
    box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);

    .date {
      display: flex;
      align-items: center;
      color: $tertiary;
      margin-bottom: 16px;
    }

    .title {
      color: $primary;
      margin-block: 12px;
    }

    p {
      text-align: justify;
    }
  }
}
