@import 'variables';

.article-card {
  background-color: #fff;
  color: $tertiary;
  border: none;
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  min-height: 500px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(0, 0, 0, 0.9) 75%
    );
    z-index: 2;
  }

  .article-image {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .article-card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding: 30px;

    .article-card-title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 0px;
      padding-inline-start: 10px;
      border-inline-start: 3px solid $secondary;

      .link {
        color: #fff;
        text-decoration: none;
      }
    }

    .article-card-content {
      display: grid;
      grid-template-rows: 0fr;
      transition: 0.5s ease-in-out;
      visibility: hidden;
      opacity: 0;

      .article-card-description {
        font-size: 15px;
        padding-top: 10px;
        margin-bottom: 0;
        overflow: hidden;
        color: #fff;
      }
    }
  }

  &:hover {
    .article-card-body {
      .article-card-content {
        grid-template-rows: 1fr;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
