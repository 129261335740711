@import 'variables';

.feature-card {
  border: 0;
  padding: 30px;
  transition: 0.3s;
  border-radius: $border-radius;
  display: flex;
  background-color: color-mix(in srgb, $primary, transparent 96%);
  border-radius: $border-radius;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  height: 100%;

  .icon {
    background-color: #fff;
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 20px;
    width: 48px;
    height: 48px;
    font-size: 22px;
    flex-shrink: 0;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px 0;
    transition: 0.3s;
    color: $primary;
  }

  .description {
    text-align: justify;
    font-size: 15px;
  }

  p {
    margin-top: 5px;

    ul {
      margin-top: 5px;
    }
  }
}
