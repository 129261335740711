@import 'variables';

.news-card {
  position: relative;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: $border-radius;

  .link {
    text-decoration: none;
  }

  .news-card-image-container {
    position: relative;
    overflow: hidden;
    border-start-start-radius: 10px;
    border-start-end-radius: 10px;

    .news-card-image {
      transition: 0.5s;
      max-width: 100%;
      height: auto;
      border-start-start-radius: 10px;
      border-start-end-radius: 10px;
    }

    .news-card-date {
      background-color: $secondary;
      color: #fff;
      position: absolute;
      right: 0;
      bottom: 0;
      text-transform: uppercase;
      font-size: 13px;
      padding: 6px 12px;
      font-weight: 500;
      border-start-end-radius: 10px;
    }
  }

  .news-card-content {
    padding: 30px;
    display: flex;
    flex-direction: column;

    .news-card-title {
      font-size: 20px;
      color: $tertiary;
      font-weight: 700;
      transition: 0.3s;
      margin-bottom: 15px;
    }

    .description {
      color: #111;
    }

    .news-card-splitter {
      color: color-mix(in srgb, $primary, transparent 60%);
      margin-bottom: 15px;
    }

    .news-card-link {
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 1;
      transition: 0.3s;
      color: $secondary !important;
      text-decoration: none;

      .news-card-link-text {
        margin-inline-end: 8px;
      }
    }
  }

  &:hover {
    .news-card-image-container {
      .news-card-image {
        transform: scale(1.1);
      }
    }
  }
}
