@import 'variables';

.indicator-card {
  position: relative;
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: 0px 0 30px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  height: 100%;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    right: -80px;
    top: -80px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transition: all 0.3s;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    right: -140px;
    top: -140px;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    transition: all 0.3s;
    z-index: -1;
  }

  &:hover::before,
  &:hover::after {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  i {
    color: #fff;
    font-size: 24px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    transform: scale(-1, 1);
  }

  h4 {
    font-weight: 600;
    margin: 15px 0 10px 0;
    transition: 0.3s;
    font-size: 20px;
  }
  h5 {
    transition: 0.3s;
  }

  p {
    line-height: 24px;
    font-size: 14px;
    margin: 10px 0 0 0;
  }

  &.up {
    &::before {
      background: color-mix(in srgb, $secondary, transparent 96%);
    }

    &::after {
      background: color-mix(in srgb, $secondary, transparent 97%);
    }

    &:hover::before,
    &:hover::after {
      background: $secondary;
    }

    i {
      background: $secondary;
    }

    &:hover {
      i {
        color: $secondary;
      }
    }
  }

  &.down {
    &::before {
      background: color-mix(in srgb, #d2122e, transparent 96%);
    }

    &::after {
      background: color-mix(in srgb, #d2122e, transparent 97%);
    }

    &:hover::before,
    &:hover::after {
      background: #d2122e;
    }

    i {
      background: #d2122e;
    }

    &:hover {
      i {
        color: #d2122e;
      }
    }
  }

  &.stable {
    &::before {
      background: color-mix(in srgb, #007FFF, transparent 96%);
    }

    &::after {
      background: color-mix(in srgb, #007FFF, transparent 97%);
    }

    &:hover::before,
    &:hover::after {
      background: #007FFF;
    }

    i {
      background: #007FFF;
    }

    &:hover {
      i {
        color: #007FFF;
      }
    }
  }

  &:hover {
    i {
      background: #fff;
    }

    h4,
    h5,
    p {
      color: #fff;
    }
  }
}
