@import 'variables';

.footer {
  color: #fff;
  background-color: $primary;
  font-size: 17px;
  padding-bottom: 24px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .footer-top {
    padding-top: 50px;

    .footer-about {
      .logo {
        line-height: 1;
        margin-bottom: 25px;
      }

      .footer-contact {
        .paragraph {
          margin-bottom: 5px;
        }
      }

      .social-links {
        .link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid #fff;
          font-size: 16px;
          color: #fff;
          margin-right: 10px;
          transition: 0.3s;

          &:hover {
            color: $secondary;
            border-color: $secondary;
            transform: scale(0.95);
          }
        }
      }
    }

    .footer-links {
      margin-bottom: 30px;

      .title {
        font-size: 16px;
        font-weight: bold;
        position: relative;
        padding-bottom: 12px;
        color: $secondary;
      }

      .list {
        list-style: none;
        padding: 0;
        margin: 0;

        .item {
          padding: 10px 0;
          display: flex;
          align-items: center;

          &:first-child {
            padding-top: 0;
          }

          .link {
            color: #fff;
            display: inline-block;
            line-height: 1;
            text-decoration: none;
            transition: 0.4s;

            &:hover {
              color: $secondary;
            }
          }
        }
      }
    }
  }

  .copyright {
    font-size: 14px;
    padding-top: 25px;
    border-top: 1px solid color-mix(in srgb, $primary, transparent 90%);
  }

  @media only screen and (max-width: 992px) {
    font-size: 14px;
  }
}
